/* Arabian */
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500&display=swap');


/* Hebrew */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');



/* Fonts */
$font-arabian: 'Tajawal', sans-serif;
$font-hebrew: 'Rubik', sans-serif;


body.lang-ar {
	font-family: $font-arabian;
}
body.lang-he {
	font-family: $font-hebrew;
}

/* bootstrap override */
.container {
	max-width: 1560px;
}
.btn:focus {
    box-shadow: none;
}
img {
	max-width: 100%;
}


.rtl .nav-first > .row {
	@media (min-width: 1200px){
		margin-left: auto;
		margin-right: 360px;
	}
}
.rtl .logo-header {
	left: auto;
	right: 15px;
	top: 15px;
}

.rtl .search-form {
	.search-submit {
		right: auto;
		left: -1px;
		top: -1px;
	}
}
.rtl .btn-login, .rtl .btn-register {
	margin-left: auto;
	margin-right: 20px;
}

.rtl .page-hero {
	text-align: right;
	
	.page-hero-title {
		padding-left: 0;
		padding-right: 20px;
		&::before {
			left: auto;
			right: 0;
		}
	}
	.coral-in {
		margin-left: 1rem;
	}
}

.rtl .pad.price {
	text-align: right;
	.pad-title {
		&::before {
			left: auto;
			right: 0;
		}
	}
	.pad-btm-item {
		left: 0px;
		right: 40px;
		padding-right: 0px;
		padding-left: 20px;
		.btn-pill {
			i {
				margin-right: 0;
				margin-left: 5px;
			}
		}
		.price {
			margin-left: 0px;
			margin-right: 20px;
		}
	}
}
.rtl .navigation-toggle {
	right: auto;
	left: 30px;
}
.rtl .main-menu {
	li {
		@media (max-width: 1200px){
			text-align: right;
			margin: 10px 20px 10px 0px;
		}
	}
}

.rtl.lang-ar .page-hero {
	.page-hero-title {
		font-family: $font-arabian;
	}
}
.rtl.lang-he .page-hero {
	.page-hero-title {
		font-family: $font-hebrew;
	}
}